import { defineStore } from "pinia";
import { Campaign } from "@/types/campaign";
import { APIClient, Response } from "@/utils/httpClient";
import router from "@/router";
import { authStore } from "./authStore";

interface ICampaignStoreState {
	campaign: Campaign | null;
	deviceId: string | null;
}

export const campaignStore = defineStore("campaignStore", {
	persist: true,
	state: () =>
		({
			campaign: null,
			deviceId: null,
		} as ICampaignStoreState),
	getters: {
		getItem(state: ICampaignStoreState) {
			return state.campaign;
		},
		getDeviceId(state: ICampaignStoreState) {
			return state.deviceId;
		},
	},
	actions: {
		async getInitialCampaignData(campaignSource: {
			campaignID: string | undefined;
			subdomain: string | undefined;
		}) {
			const endpoint = `/campaigns/initial`;
			try {
				const response = await APIClient.post(endpoint, campaignSource, {
					headers: { auth_type: "manager" },
				});
				if (response.success) {
					console.log(response.data);
					return response.data;
				}
			} catch (error: any) {
				console.error(error);
				if (error instanceof Error) {
					router.push(`/error?message=${error.message}`);
				} else {
					router.push(`/error?message=Unknown error`);
				}
			}
		},
		async getCampaignFromPath(path: string): Promise<Response> {
			const endpoint = `/campaigns/path/${path}`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.campaign = response.data;
					return response;
				}
			} catch (error: any) {
				console.error(error);
				if (error instanceof Error) {
					router.push(`/error?message=${error.message}`);
				} else {
					router.push(`/error?message=Unknown error`);
				}
			}
			return { success: false, data: null, message: "No campaign found" };
		},
		setCampaign(item: Campaign): void {
			this.campaign = item;
		},
		setDeviceId(id: string): void {
			this.deviceId = id;
		},
		async getCampaign() {
			const authState = authStore();
			const campaignID = authState.campaignID;
			const endpoint = `/campaigns/${campaignID}`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.campaign = response.data;
					return response.data;
				} else {
					if (
						response.data &&
						response.data.status &&
						response.data.status == "CAMPAIGN-ENDED"
					) {
						router.replace(`/${campaignID}/campaign-over`);
					} else {
						return null;
					}
				}
			} catch (error: any) {
				console.error(error);
				if (error instanceof Error) {
					router.push(`/error?message=${error.message}`);
				} else {
					router.push(`/error?message=Unknown error`);
				}
			}
		},
		async shareCampaign() {
			const authState = authStore();
			const campaignID = authState.campaignID;
			const userID = authState.userID;
			const endpoint = `/campaigns/${campaignID}/users/${userID}/shared`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					console.log("Campaign Shared");
				} else {
					if (
						response.data &&
						response.data.status &&
						response.data.status == "CAMPAIGN-ENDED"
					) {
						router.replace(`/${campaignID}/campaign-over`);
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
});
