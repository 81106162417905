import router from "@/router";
import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";

import { jwtDecode } from "jwt-decode";
import { ErrorLogger } from "./frontendErrorEvent";
import { authStore } from "@/store/pinia_modules/authStore";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export interface AuthToken {
	AccessToken: string;
	ExpiresIn: string;
	IdToken: string;
	RefreshToken: string;
	TokenType: string;
}

export interface AuthenticationResult {
	AuthenticationResult: AuthToken;
	WalletID: string;
}

export interface Response {
	success: boolean;
	data: AuthenticationResult & any;
	message: string;
}

declare module "axios" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface AxiosResponse extends Promise<Response> {}
}

export class HttpClient {
	protected instance: AxiosInstance;

	public constructor(url: string) {
		this.instance = axios.create({
			baseURL: url,
		});
		this._initializeResponseInterceptor();
	}

	// private setInstance(config: AxiosRequestConfig): void {
	// 	this.instance = axios.create(config);
	// 	this._initializeResponseInterceptor();
	// }

	private _initializeResponseInterceptor = () => {
		this.instance.interceptors.request.use(this._handleRequest as any);
		this.instance.interceptors.response.use(
			this._handleResponse,
			this._handleError
		);
	};

	private _handleRequest = async (config: AxiosRequestConfig) => {
		const authState = authStore();
		if (config.headers === undefined) {
			config.headers = {};
		}
		ErrorLogger.INFO(
			`HTTP CLIENT REQUEST : ${config.method}, ${config.url}, ${
				config.data ? JSON.stringify(config.data) : ""
			}`
		);
		config.headers["auth_type"] = "user";
		const accessToken = localStorage.getItem("AccessToken");
		if (accessToken && authState.campaignID && authState.userID) {
			if (
				HttpClient.checkExpired(accessToken) &&
				config.url !=
					`/campaigns/${authState.campaignID}/users/${authState.userID}/refresh`
			) {
				try {
					const refreshToken = localStorage.getItem("RefreshToken");
					if (refreshToken) {
						const res = await this.refreshToken(refreshToken);
						if (res.data.AuthenticationResult) {
							console.log(res.data);
							localStorage.setItem(
								"AccessToken",
								res.data.AuthenticationResult.AccessToken
							);
							authState.saveWalletID(res.data.WalletID);
							config.headers["Authorization"] = localStorage.getItem(
								"AccessToken"
							) as string;
						} else {
							this.clearTokensLogout();
						}
					} else {
						this.clearTokensLogout();
					}
				} catch (error) {
					console.error(error);
					this.clearTokensLogout();
				}
			} else {
				config.headers["Authorization"] = localStorage.getItem(
					"AccessToken"
				) as string;
			}
		} else {
			this.clearTokensLogout();
		}

		return config;
	};

	private _handleResponse = (resp: AxiosResponse) => {
		if (!resp.data.success) {
			ErrorLogger.ERROR(
				`HTTP CLIENT REQUEST UNSUCCESSFUL :  ${
					resp.data ? JSON.stringify(resp.data) : ""
				}`
			);
		}
		return resp.data;
	};

	protected _handleError = async (
		err: AxiosError
	): Promise<AxiosError | AxiosInstance> => {
		ErrorLogger.ERROR(`HTTP CLIENT ERROR : ${JSON.stringify(err)}`);
		const error = err.response as any;
		console.log(error);
		if (error.status === 401 && error.data.message == "user blocked") {
			alert("This account has been locked");
			this.clearTokensLogout();
		} else {
			if (err.response?.status != 400 && err.response?.status != 401) {
				this.showErrorPage(
					`HTTP CLIENT ERROR : ${JSON.stringify(err)}`,
					"An application error occured"
				);
			}
		}
		return Promise.reject(err);
	};

	public static checkExpired(accessToken: string): boolean {
		if (accessToken && accessToken !== "") {
			const decoded: any = jwtDecode(accessToken);
			if (decoded) {
				if (decoded["exp"] < Date.now() / 1000) {
					return true;
				}
			} else {
				return true;
			}
		}

		return false;
	}

	private showErrorPage(errorMessage: string, shortMessage?: string) {
		navigationStore().loading = false;
		router.push(`/error?message=${errorMessage}&shortMessage=${shortMessage}`);
	}

	public clearTokensLogout(): void {
		if (
			router.currentRoute.value.name !== "Register" &&
			router.currentRoute.value.name !== "SignIn" &&
			router.currentRoute.value.name !== "Enter Pin"
		) {
			if (router.currentRoute.value.name !== undefined) {
				localStorage.removeItem("AccessToken");
				localStorage.removeItem("IdToken");
				localStorage.removeItem("RefreshToken");
				router.push(`/${authStore().campaignID}`);
			}
		}
	}

	public refreshToken = (token: string): Promise<AxiosResponse<Response>> => {
		return this.instance.post<Response>(
			`/campaigns/${authStore().campaignID}/users/${
				authStore().userID
			}/refresh`,
			{
				token: token,
			}
		);
	};

	public get(
		url: string,
		config?: AxiosRequestConfig<any> | undefined
	): Promise<AxiosResponse<Response>> {
		return this.instance.get<Response>(url, config);
	}

	public post(
		url: string,
		data?: any,
		config?: AxiosRequestConfig<any>
	): Promise<AxiosResponse<Response>> {
		return this.instance.post<Response>(url, data, config);
	}
}

export const APIClient = new HttpClient(
	import.meta.env.VITE_APP_BACKEND_URL as string
);
