import { createApp } from "vue"
import App from "./App.vue"
import VueGtag from "vue-gtag"
import router from "./router"
import * as Sentry from "@sentry/vue"

import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faTimes,
    faBars,
    faCircleNotch,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome"
import ContainerObject from "@/components/layout/ContainerObject.vue"
import { CustomLogger } from "./utils/logger"

// Override the global console object
new CustomLogger()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App).use(pinia).use(router)

console.info("STAGE", import.meta.env.VITE_APP_STAGE)
console.info("VERSION", import.meta.env.VITE_APP_VERSION)

if (
    import.meta.env.VITE_APP_STAGE === "dev" ||
    import.meta.env.VITE_APP_STAGE === "staging"
) {
    console.info("Sentry enabled")
    Sentry.init({
        app: app,
        dsn: "https://7c4fe140ec4a0e449280a5ef39aeb878@o4506625196556288.ingest.sentry.io/4506721688879104",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/app.dev.viviewer\.com\//,
            /^https:\/\/app.stage.viviewer\.com\//,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

library.add(faTimes)
library.add(faBars)
library.add(faCircleNotch)
app.component("font-awesome-icon", FontAwesomeIcon)
app.component("font-awesome-layers", FontAwesomeLayers)

app.component("ContainerObject", ContainerObject)

app.use(VueGtag, {
    config: { id: import.meta.env.VITE_APP_GA_MEASUREMENT_ID || "" },
})

app.mount("#app")
