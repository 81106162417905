import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { APIClient } from "@/utils/httpClient";

export const generateShare = async (
	campaignID: string,
	userID: string,
	dropPoolID: string | null
): Promise<string | null> => {
	try {
		const endpoint = `/campaigns/${campaignID}/share`;
		const response = await APIClient.post(endpoint, { userID, dropPoolID });
		if (response.success) {
			if (response.data.updateWallet) {
				await vItemStore().getItems();
			}
			return response.data.url;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
};
