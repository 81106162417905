<template>
	<button
		:id="element.attributes.id"
		:ref="element.attributes.id"
		@click="doAction(element.action)"
		@keyup.enter="doKeyUpAction(element.action)"
		:style="element.styles"
	>
		{{ element.text }}
	</button>
</template>

<script lang="ts">
import { Action, Container } from "@/types/customPage";
import { defineComponent, PropType } from "vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	methods: {
		gotoPage(page: string) {
			this.$router
				.push({
					path: page,
				})
				.catch((err) => {
					console.log(err);
				});
		},
		doAction(action: Action) {
			navigationStore().emitAction(action);
		},
		doKeyUpAction(action: Action) {
			if (action.value == "signin-request-code" || action.value == "signup") {
				navigationStore().emitAction(action);
			}
		},
	},
});
</script>

<style scoped lang="scss"></style>
