import { defineStore } from "pinia";
import { CategorySplit, DropLocation, DropPool } from "@/types/dropPool";
import { isInRadius } from "@/utils/geolocationTools";
import { isInDateRange } from "@/utils/dateTools";
import { APIClient } from "@/utils/httpClient";
import { Category } from "@/types/category";
import { PoolSegment } from "@/types/poolSegment";
import { poolSegmentStore } from "./poolSegmentStore";
import { categoryStore } from "./categoryStore";
import { authStore } from "./authStore";
import { prizeStore } from "./prizeStore";

interface IDropPoolStoreState {
	pools: DropPool[];
	userLocation: [number, number];
	locationAccuracy: number;
	updatingLocation: boolean;
	geolocationPermissionStatus: string;
	cameraPermissionStatus: string;
	permissionGranted: boolean;
	activePool: DropPool | null;
	activeDropLocation: DropLocation | null;
	loading: boolean;
	watcher: number;
}

export const dropPoolStore = defineStore("dropPoolStore", {
	persist: true,
	state: () =>
		({
			pools: [],
			lastUserLocation: [0, 0],
			userLocation: [0, 0],
			locationAccuracy: 100000,
			distanceTraveled: 0,
			updatingLocation: false,
			geolocationPermissionStatus: "denied",
			cameraPermissionStatus: "denied",
			permissionGranted: false,
			activePool: null,
			activeDropLocation: null,
			loading: false,
			watcher: -1,
		} as IDropPoolStoreState),
	getters: {
		getPools(state: IDropPoolStoreState) {
			return state.pools;
		},
		getLoading(state: IDropPoolStoreState) {
			return state.loading;
		},
		getPoolByID: (state: IDropPoolStoreState) => (id: string) => {
			return state.pools.find((e: DropPool) => e.id == id);
		},
		getActivePool(state: IDropPoolStoreState) {
			return state.activePool;
		},
		getActiveDropLocation(state: IDropPoolStoreState) {
			return state.activeDropLocation;
		},
		getGeolocationPermissionStatus(state: IDropPoolStoreState) {
			return state.geolocationPermissionStatus;
		},
		getPermissionGranted(state: IDropPoolStoreState): boolean {
			return state.permissionGranted;
		},
		getUserLocation(state: IDropPoolStoreState) {
			return state.userLocation;
		},
		getLocationAccuracy(state: IDropPoolStoreState) {
			return state.locationAccuracy;
		},
		getWatcher(state: IDropPoolStoreState) {
			return state.watcher;
		},
		getDummyVatLocations(state: IDropPoolStoreState) {
			const pools = state.pools.filter((e: DropPool) => {
				return (
					isInDateRange(
						new Date().toISOString(),
						`${e.startDate}T00:00:00.000Z`,
						`${e.endDate}T23:59:59.999Z`
					) &&
					e.dropType == "gps" &&
					e.enabled == true
				);
			});
			const segments = poolSegmentStore().segments;
			const dummyLocations: DropLocation[] = [];
			for (let i = 0; i < pools.length; i++) {
				const segment = segments.find(
					(e: PoolSegment) => e.poolID == pools[i].id
				);
				if (segment) {
					const categories = segment!.items.map(
						(e: CategorySplit) => e.categoryID
					);
					for (let j = 0; j < pools[i].dropLocations.length; j++) {
						const loc = pools[i].dropLocations[j];
						loc.radius = pools[i].dropLocationRadius;
						const cat: Category | undefined = categoryStore().getItemByID(
							categories[Math.floor(Math.random() * categories.length)]
						);
						if (cat && cat.image) {
							loc.marker = cat.image;
							dummyLocations.push(loc);
						}
					}
				}
			}
			return dummyLocations;
		},
	},
	actions: {
		saveItems(pools: DropPool[]) {
			const enabledPools = pools.filter((e) => e.enabled == true);
			this.pools = enabledPools;
		},
		setLoading(loading: boolean) {
			this.loading = loading;
		},
		clearItems() {
			this.pools = [];
		},
		saveWatcher(watcher: number) {
			this.watcher = watcher;
		},
		saveUserLocation(location: [number, number]) {
			this.userLocation = location;
			// store.commit("dropPoolStore/saveActiveDropPool");
		},
		saveLocationAccuracy(locationAccuracy: number) {
			this.locationAccuracy = locationAccuracy;
		},
		saveActiveDropPool() {
			const pools = this.pools.filter((e: DropPool) => {
				return isInDateRange(
					new Date().toISOString(),
					`${e.startDate}T${e.dropIntervalStart}:00.000Z`,
					`${e.endDate}T${e.dropIntervalEnd}:00.000Z`
				);
			});

			//filter pools for if in range of dropLocationRadius for gps, if so take priority over pin
			const gpsPool = pools.find((e: DropPool) => {
				if (e.dropType == "gps") {
					const location = e.dropLocations.find((f: DropLocation) =>
						isInRadius(
							[this.userLocation[1], this.userLocation[0]],
							[f.latitude, f.longitude],
							e.dropLocationRadius
						)
					);
					if (location) {
						this.activeDropLocation = location;
						return true;
					}
				}
			});
			const pinDropPool = pools.find((e: DropPool) => e.dropType == "pin");

			if (gpsPool) {
				this.activePool = gpsPool;
			} else if (pinDropPool) {
				this.activePool = pinDropPool;
				this.activeDropLocation = null;
			} else {
				this.activePool = null;
				this.activeDropLocation = null;
			}
		},
		resetActiveDropPool() {
			this.activePool = null;
			this.activeDropLocation = null;
		},
		async getDropPools() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/dropPools`;
			try {
				const response = await APIClient.get(endpoint);
				if (response.success) {
					this.saveItems(response.data);
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async getMapData() {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/mapdata`;
			try {
				const response = await APIClient.get(endpoint);

				if (response.success) {
					this.saveItems(response.data.pools);
					categoryStore().addItems(response.data.categories);
					prizeStore().saveItems(response.data.prizes);
					poolSegmentStore().setItems(response.data.segments);
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		async renderARTemplate(params: {
			arTemplateID: string;
			walletID: string;
			dropPoolID: string;
		}) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/artemplates/${params.arTemplateID}/render`;
			try {
				const response = await APIClient.post(endpoint, {
					walletID: params.walletID,
					dropPoolID: params.dropPoolID,
				});
				if (response.success) {
					return response.data;
				}
			} catch (error: any) {
				console.error(error);
			}
		},
	},
});
