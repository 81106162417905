<template>
	<div id="holder">
		<iframe id="ar-iframe" :srcdoc="srcDoc"></iframe>
		<ModalComponent
			:show="showClaimModal"
			@close="showClaimModal = false"
			@claim="claimItem()"
		/>
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import ModalComponent from "@/components/utils/ModalComponent.vue";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { getUserLocation } from "@/utils/geolocationTools";
import { Action, Container } from "@/types/customPage";
import { DropLocation, DropPool } from "@/types/dropPool";
import { defineComponent, PropType } from "vue";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { authStore } from "@/store/pinia_modules/authStore";
import { poolSegmentStore } from "@/store/pinia_modules/poolSegmentStore";

export default defineComponent({
	props: {
		element: {
			type: Object as PropType<Container>,
			required: true,
		},
	},
	components: { ModalComponent, LoadingComponent },
	data() {
		return {
			showClaimModal: false,
			selectedItemID: "",
			srcDoc: "",
			iframeElement: null as HTMLIFrameElement | null,
			loading: false,
			reachedAccuracy: false,
			initNoItems: false,
			rendered: false,
		};
	},

	mounted() {
		const id = dropPoolStore().getWatcher;
		navigator.geolocation.clearWatch(id);
		navigationStore().isInARView();
		dropPoolStore().saveLocationAccuracy(1000000000);
		this.iframeListener();
		this.checkForEnhancedAI();
	},
	computed: {
		vItems() {
			return vItemStore().getDroppedItems;
		},
		action(): Action {
			return navigationStore().getAction;
		},
		activePool(): DropPool | null {
			return dropPoolStore().getActivePool;
		},
		activeDropLocation(): DropLocation | null {
			return dropPoolStore().getActiveDropLocation;
		},
		loading(): boolean {
			return dropPoolStore().getLoading;
		},
		walletID(): string | null {
			return authStore().getWalletID;
		},
		userLocation(): [number, number] {
			return dropPoolStore().getUserLocation;
		},
		locationAccuracy(): number {
			return dropPoolStore().getLocationAccuracy;
		},
	},
	watch: {
		action(to: Action): void {
			if (to) {
				if (to.type == "claim-item") {
					this.claimItem();
				}
			}
		},
		locationAccuracy(to: number) {
			if (to && to <= 100 && !this.reachedAccuracy) {
				// if (to && to <= 1000000 && !this.reachedAccuracy) {
				this.reachedAccuracy = true;
				this.init();
			}
		},
		userLocation(to: [number, number]) {
			if (to) {
				dropPoolStore().saveActiveDropPool();
			}
		},
	},
	methods: {
		async checkForEnhancedAI() {
			if (this.initNoItems && this.vItems.length == 0) {
				this.loading = false;
				return;
			}

			if (this.vItems.length > 0 && !this.rendered) {
				//drop items here if bypass map
				this.loading = true;
				const walletID = authStore().getWalletID;
				this.rendered = true;
				this.srcDoc = await dropPoolStore().renderARTemplate({
					walletID: walletID || "",
					arTemplateID:
						(this.activePool!.useEnhancedAI
							? this.activePool!.ARTemplateID
							: "legacy") || "",
					dropPoolID: this.activePool!.id || "",
				});
				this.loading = false;
			} else {
				if (this.vItems.length == 0) {
					//get user location
					this.loading = true;

					this.initNoItems = true;
					this.initUserLocation();
				}
			}
		},
		async init() {
			vItemStore().clearItems();
			dropPoolStore().saveActiveDropPool();

			await this.dropItems();
			this.checkForEnhancedAI();
		},
		initUserLocation() {
			getUserLocation();
		},
		setGetLocationTimeout() {
			setTimeout(() => {
				if (!this.reachedAccuracy) {
					navigationStore().emitAction({
						type: "open-popup",
						value: "location-error",
					});
					this.loading = false;
				}
			}, 10000);
		},
		async getPoolsCategoriesPrizes() {
			await dropPoolStore().getMapData();
		},
		async getCampaignSegments() {
			poolSegmentStore().getCampaignSegments();
		},
		async dropItems() {
			if (this.activePool == null && this.activeDropLocation == null) {
				navigationStore().emitAction({
					type: "open-popup",
					value: "out-of-drop-zone",
				});

				return false;
			}

			if (this.vItems.length == 0 && this.activePool) {
				await vItemStore().recycleItems({
					walletID: this.walletID!,
					poolID: this.activePool.id!,
				});
				await vItemStore().dropItems({
					walletID: authStore().getWalletID || "",
					segmentID: "null",
					poolID: this.activePool.id!,
					userLocation: this.userLocation,
				});
			}
			return true;
		},

		selectItem(id: string) {
			this.selectedItemID = id;
			navigationStore().emitAction({
				type: "open-popup",
				value: "claim-item",
			});
		},
		async claimItem() {
			this.loading = true;
			let id = "";
			if (this.selectedItemID.includes("webar-marker-")) {
				id = this.selectedItemID.split("/")[1];
			} else {
				id = this.selectedItemID;
			}
			await vItemStore().claimItem(id);

			this.sendMessage("itemClaimed", id);

			this.showClaimModal = false;
			this.loading = false;
		},
		sendMessage(event: string, message: any) {
			const msg = JSON.stringify({
				event: event,
				message: message,
			});
			this.iframeElement!.contentWindow!.postMessage(msg, "*");
		},
		iframeListener() {
			this.iframeElement = document.getElementById(
				"ar-iframe"
			) as HTMLIFrameElement;
			window.addEventListener("message", (e) => {
				const data = JSON.parse(e.data);
				switch (data.event) {
					case "claimItem":
						this.selectItem(data.message);
						break;
					case "alreadyClaimed":
						navigationStore().emitAction({
							type: "open-popup",
							value: "already-claimed",
						});
						break;
					case "emitAction":
						navigationStore().emitAction(data.message);
						break;
				}
			});
		},
	},
});
</script>

<style scoped lang="scss">
#holder {
	width: 100%;
	height: 100%;
	background-color: #000;
}
#ar-iframe {
	width: 100%;
	height: 100%;
	border-style: none;
	background-color: #000;
}

a-scene {
	width: 100%;
	height: 100%;
}
.ar-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 100;
}
.cross-hair {
	position: absolute;
	top: 50%;
	left: 50%;
	.hair {
		width: 200px;
		border-style: solid;
		border-width: 1px 0 0 0;
		border-color: #fff;
		position: absolute;
		transform: translate(-50%, -50%);
		opacity: 0.2;
	}
	.vertical {
		transform: rotate(90deg);
		left: -100px;
	}
}
</style>
