import { APIClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import { authStore } from "./authStore";
import { vItemStore } from "./vItemStore";

export enum PendingEventType {
	COLLECT_SHARE = "collect-share",
}

export interface PendingEvent {
	event: PendingEventType;
	value: any;
	id: string;
}

interface IPendingEventStoreState {
	queue: PendingEvent[];
}

export const pendingEventStore = defineStore("pendingEventStore", {
	persist: true,
	state: () =>
		({
			queue: [],
		} as IPendingEventStoreState),
	getters: {
		getNextEvent(state: IPendingEventStoreState): PendingEvent | null {
			if (state.queue.length > 0) {
				return state.queue[0];
			}
			return null;
		},
	},
	actions: {
		addEventToQueue(event: PendingEvent) {
			this.queue.push(event);
		},
		completeEvent(id: string) {
			try {
				for (let index = 0; index < this.queue.length; index++) {
					if (this.queue[index].id == id) {
						this.queue.splice(index, 1);
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		async addEvent(event: PendingEvent) {
			this.addEventToQueue(event);
		},
		async startPendingEvents() {
			try {
				for (let i = 0; i < this.queue.length; i++) {
					switch (this.queue[i].event) {
						case PendingEventType.COLLECT_SHARE:
							pendingEventStore().collectShare(this.queue[i]);
							break;

						default:
							break;
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		async collectShare(event: PendingEvent) {
			const campaignID = authStore().campaignID;
			const endpoint = `/campaigns/${campaignID}/share/${event.value}`;
			try {
				const userID = authStore().userID;
				const response = await APIClient.post(endpoint, { userID });
				if (response.success) {
					if (response.data.updateWallet) {
						// update wallet state
						vItemStore().getItems();
					}
					this.completeEvent(event.id);
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
});
